import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

type Meta = React.DetailedHTMLProps<React.MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>

export interface Props {
  description?: string
  lang?: string
  meta?: Meta[]
  title: string
}

const SEO: React.FC<Props> = (props) => {
  const {
    description,
    lang,
    meta,
    title
  } = props;

  const intl = useIntl();

  const { site, ogImage, ogLogo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        ogImage: file(relativePath: {eq: "og-image.jpg"}) {
          publicURL
        }
        ogLogo: file(relativePath: {eq: "og-logo.png"}) {
          childImageSharp {
            size500: resize(width: 500, height: 500) {
              src
            }
            size250: resize(width: 250, height: 250) {
              src
            }
            size150: resize(width: 150, height: 150) {
              src
            }
          }
        }
      }
    `
  );

  const defaultDescription = intl.formatMessage({
    id: 'Services around places you care about',
    defaultMessage: 'Services around places you care about',
  });

  const metaDescription = description || defaultDescription;

  const defaultMeta: Meta[] = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage.publicURL,
    },
    {
      property: 'og:logo',
      content: ogLogo.childImageSharp.size500.src,
      ...({
        size: '500x500',
      }),
    },
    {
      property: 'og:logo',
      content: ogLogo.childImageSharp.size250.src,
      ...({
        size: '250x250',
      }),
    },
    {
      property: 'og:logo',
      content: ogLogo.childImageSharp.size150.src,
      ...({
        size: '150x150',
      }),
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: '@QouldntAB'
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang: intl.locale ? intl.locale.split('-')[0] : lang,
      }}
      meta={defaultMeta.concat(meta)}
      title={title}
      titleTemplate={`%s • ${site.siteMetadata.title}`}
    />
  );
};

SEO.displayName = 'FcSEO';

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

export default SEO;
