import React from 'react';
import cc from 'classcat';
import { Link } from 'gatsby';

interface Props {
  children: React.ReactChildren | string;
  className?: string;
  currentPath: string;
  locale: string;
}

const LocaleLink: React.FC<Props> = (props) => {
  const { children, className, currentPath, locale } = props;

  const pathWithoutLocale = currentPath.replace(/\/?\w{2}-\w{2}\/?/g, '');

  const defaultClassName = cc([
    'fc-segment-bar__locale',
    'fc-segment-bar__link',
    'font-bold',
    'ml-8',
    'text-gray-700',
    'dark:text-gray-500',
    'hover:text-gray-900',
    'focus-visible:text-gray-900',
    'dark:hover:text-white',
    'dark:focus-visible:text-white',
    'focus:outline-none',
    'motion-safe:transition',
    'duration-150',
    'border-b-4',
    'border-transparent',
    'hover:border-primary',
    'focus-visible:border-primary',
  ]);

  return (
    <Link
      to={`/${locale}/${pathWithoutLocale}`}
      lang={locale}
      getProps={({ isPartiallyCurrent }) => ({
        tabIndex: isPartiallyCurrent ? -1 : null,
        className: cc([
          defaultClassName,
          className,
          {
            'border-primary': isPartiallyCurrent,
            '!text-primary': isPartiallyCurrent,
          },
        ]),
      })}
    >
      {children}
    </Link>
  );
};

LocaleLink.displayName = 'FcSegmentBar.LocaleLink';

export default LocaleLink;
