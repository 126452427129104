import { IntlShape } from "gatsby-plugin-intl";

export interface NavItem {
  children?: NavItemChild
  className?: string
  customColor?: string
  name: React.ReactNode
  to?: string
}

export interface NavItemChild {
  className?: string
  customColor?: string
  name: React.ReactNode
  to: string
}

export const navItems = (intl: IntlShape): NavItem[] => [
  {
    to: '/services',
    name: intl.formatMessage({
      id: 'Services',
      defaultMessage: 'Services',
    }),
  },
  /* {
    to: '/#company',
    name: intl.formatMessage({
      id: 'Company',
      defaultMessage: 'Company',
    }),
  }, */
  {
    to: '/#principles',
    name: intl.formatMessage({
      id: 'Principles',
      defaultMessage: 'Principles',
    }),
  },
  {
    to: '/#contact',
    name: intl.formatMessage({
      id: 'Contact',
      defaultMessage: 'Contact',
    }),
    customColor: 'text-primary hover:text-primary-active focus-visible:text-primary-active',
  },
];
