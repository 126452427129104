import React from 'react';
import cc from 'classcat';
import { FormattedMessage, IntlContextConsumer, useIntl } from 'gatsby-plugin-intl';

import LegalLink from './components/legal-link';
import LocaleLink from './components/locale-link';
import { localeNames } from '../../data';

export interface Props {
  path: string;
  onCookiesClick: (event: React.SyntheticEvent) => void;
}

const SegmentBar: React.FC<Props> = (props) => {
  const { path, onCookiesClick } = props;

  const intl = useIntl();

  return (
    <div className="fc-segment-bar w-full bg-gray-200 dark:bg-gray-800 print:hidden">
      <div
        className={cc([
          'container',
          'max-w-screen-xl',
          'flex',
          'justify-between',
          'mx-auto',
          'text-xs',
          'align-middle',
          'leading-8',
        ])}
      >
        <div className="flex">
          <IntlContextConsumer>
            {({ languages: locales }) =>
              locales.map((locale: string) => (
                <LocaleLink key={locale} locale={locale} currentPath={path}>
                  {localeNames[locale] || locale}
                </LocaleLink>
              ))
            }
          </IntlContextConsumer>
        </div>
        <div className="flex mr-8">
          <LegalLink
            to="/legal/cookies"
            onClick={(event) => {
              if (!event.metaKey) {
                event.persist();
                event.preventDefault();

                event?.currentTarget?.blur();
                onCookiesClick(event);
              }
            }}
            role="button"
            title={intl.formatMessage({
              id: 'Show settings and information about cookies',
              defaultMessage: 'Show settings and information about cookies',
            })}
          >
            <FormattedMessage id="Cookies" defaultMessage="Cookies" />
          </LegalLink>
          <LegalLink to="/legal/privacy" className="hidden md:block">
            <FormattedMessage id="Privacy" defaultMessage="Privacy" />
          </LegalLink>
          <LegalLink to="/legal/terms" className="hidden md:block">
            <FormattedMessage id="Terms" defaultMessage="Terms" />
          </LegalLink>
        </div>
      </div>
    </div>
  );
};

SegmentBar.displayName = 'FcSegmentBar';

export default SegmentBar;
