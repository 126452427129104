import React from 'react';
import cc from 'classcat';
import { Link } from 'gatsby-plugin-intl';

interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  role?: string;
  title?: string;
  to: string;
}

const LegalLink: React.FC<Props> = (props) => {
  const { children, className, onClick, role, title, to } = props;

  const defaultClassName = cc([
    'fc-segment-bar__link',
    'fc-segment-bar__legal',
    'ml-8',
    'first:ml-0',
    'font-bold',
    'text-gray-700',
    'hover:text-gray-900',
    'focus-visible:text-gray-900',
    'dark:text-gray-500',
    'dark:hover:text-white',
    'dark:focus-visible:text-white',
    'cursor-pointer',
    'motion-safe:transition',
    'duration-150',
    'focus:outline-none',
    'border-b-4',
    'border-transparent',
    'hover:border-primary',
    'focus-visible:border-primary',
  ]);

  return (
    <Link
      to={to}
      getProps={({ isCurrent }) => ({
        tabIndex: !onClick && isCurrent ? -1 : null,
        className: cc([
          defaultClassName,
          className,
          {
            'border-primary': isCurrent,
            '!text-primary': isCurrent,
          },
        ]),
      })}
      onClick={onClick}
      role={role}
      title={title}
    >
      {children}
    </Link>
  );
};

LegalLink.displayName = 'FcLegalLink';

export default LegalLink;
