import React from 'react';
import cc from 'classcat';
import { Link } from 'gatsby-plugin-intl';

export interface Props {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  focusable?: boolean
  href?: string
  small?: boolean
  type?: 'a' | 'internal' | 'button' | 'reset' | 'submit'
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'warning' | 'info' | 'default'
  [attr: string]: any
}

const Button: React.FC<Props> = (props) => {
  const {
    children,
    className,
    disabled,
    focusable,
    href,
    small,
    type,
    variant,
    ...restProps
  } = props;

  const variantClasses = (() => {
    switch (variant) {
      case 'primary': return cc({
        'text-gray-900 bg-primary hover:bg-primary-active focus-visible:bg-primary-active': !disabled,
        'text-gray-700 bg-gray-400': disabled,
      });
      case 'secondary': return cc({
        'text-gray-900 bg-primary border-primary-active hover:bg-primary-active focus-visible:bg-primary-active': !disabled,
        'text-gray-700 bg-gray-400': disabled,
      });
      case 'tertiary': return cc({
        'text-gray-900 hover:text-primary-active focus-visible:text-primary-active bg-white hover:bg-gray-100 focus-visible:bg-gray-100 hover:border-primary-active focus-visible:border-primary-active': !disabled,
        'text-gray-700 bg-gray-400': disabled,
      });
      case 'danger': return 'text-gray-800 bg-red-400 hover:bg-red-500';
      case 'warning': return 'text-gray-800 bg-orange-400 hover:bg-orange-500';
      case 'info': return 'text-white bg-blue-400 hover:bg-blue-500';
      case 'default': // Fallthrough
      default: return cc({
        'text-gray-900 bg-gray-400 hover:bg-gray-300 border-gray-300': !disabled,
      });
    }
  })();

  const buttonClasses = cc([
    className,
    'fc-button',
    `fc-button--${variant}`,
    'px-5',
    'border-2',
    'border-transparent',
    'text-base',
    'leading-6',
    'font-medium',
    'rounded-full',
    'focus:outline-none',
    'motion-safe:transition',
    'duration-150',
    'ease-in-out',
    'focus-visible:ring-2',
    'ring-primary',
    'ring-offset-2',
    'ring-offset-gray-100',
    'dark:ring-offset-gray-900',
    '!no-underline',
    variantClasses,
    {
      'py-1': small,
      'py-3': !small,
      'focus-visible:shadow-outline': focusable,
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
    },
  ]);

  const extraProps = {
    className: buttonClasses,
    // tabIndex: disabled ? -1 : null,
  };

  if (type === 'a') {
    return (
      <a
        {...restProps}
        {...extraProps}
        href={href}
      >
        {children}
      </a>
    );
  }

  if (type === 'internal') {
    return (
      <Link
        {...restProps}
        {...extraProps}
        to={href}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      {...restProps}
      {...extraProps}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.displayName = 'FcButton';

Button.defaultProps = {
  disabled: false,
  focusable: false,
  small: false,
  type: 'a',
  variant: 'default',
};

export default Button;
