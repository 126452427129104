import React from 'react';
import { SiFacebook, SiInstagram, SiLinkedin, SiX } from '@icons-pack/react-simple-icons';

export const socialMedias = [
  {
    key: 'facebook',
    icon: <SiFacebook fr={{}} />,
    title: 'Facebook',
    href: 'https://www.facebook.com/Qouldnt',
  },
  {
    key: 'instagram',
    icon: <SiInstagram fr={{}} />,
    title: 'Instagram',
    href: 'https://www.instagram.com/qouldnt_ab',
  },
  {
    key: 'x',
    icon: <SiX fr={{}} />,
    title: 'Twitter',
    href: 'https://twitter.com/QouldntAB',
  },
  {
    key: 'linkedin',
    icon: <SiLinkedin fr={{}} />,
    title: 'LinkedIn',
    href: 'https://www.linkedin.com/company/qouldnt/',
  },
];
