import React from 'react';
import cc from 'classcat';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Cookies from 'js-cookie';
import { RenderRichTextData } from 'gatsby-source-contentful/rich-text';

import Message from './message';
import Hero from '../hero';
import Footer from '../footer';
import Navbar from '../navbar';
import SegmentBar from '../segment-bar';

import { navItems } from '../../data';

const CookieConsent = React.lazy(() => import('../cookie-consent'));

type GlobalMessage = {
  id: string;
  name: string;
  message: RenderRichTextData<any>;
  textColor: string;
  node_locale: string;
  backgroundColor: string;
  hideAfter: string;
  hideBefore: string;
};

interface Data {
  globalMessages: {
    edges: {
      node: GlobalMessage;
    }[];
  };
}

interface Props {
  breadcrumbs?: React.ReactNode;
  buttons?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  image?: React.ReactNode;
  messages?: React.ReactNode[];
  path: string;
  preMain?: React.ReactNode;
  subTitle?: React.ReactNode;
  title?: React.ReactNode;
}

const Layout: React.FC<Props> = (props) => {
  const {
    breadcrumbs,
    buttons,
    children,
    className,
    image,
    messages,
    path,
    preMain,
    subTitle,
    title,
  } = props;

  const intl = useIntl();

  const isSSR = typeof window === 'undefined';

  const [cookieConsentOpen, setCookieConsentOpen] = React.useState<boolean>(
    !Cookies.get('consentLevelCookie')
  );
  const [cookieConsentTrigger, setCookieConsentTrigger] = React.useState<HTMLElement>();

  const data: Data = useStaticQuery(graphql`
    {
      globalMessages: allContentfulGlobalMessage {
        edges {
          node {
            id
            name
            message {
              raw
            }
            textColor
            node_locale
            hideAfter
            hideBefore
            backgroundColor
          }
        }
      }
    }
  `);

  return (
    <>
      <Helmet
        htmlAttributes={{
          class: cc([
            'text-gray-900',
            'dark:text-white',
            'antialiased',
            'leading',
            'transition-colors',
            'duration-75',
            'ease-in-out',
          ]),
        }}
        bodyAttributes={{
          class: cc(['min-h-screen', 'bg-gray-100', 'dark:bg-gray-900']),
        }}
      />
      <div className="Layout Layout--hero h-full" id="main">
        {data.globalMessages &&
          data.globalMessages.edges
            .filter((globalMessage) => globalMessage.node.node_locale === intl.locale)
            .filter((globalMessage) => {
              if (!globalMessage.node.hideBefore) {
                return true;
              }

              return new Date(globalMessage.node.hideBefore).getTime() < new Date().getTime();
            })
            .filter((globalMessage) => {
              if (!globalMessage.node.hideAfter) {
                return true;
              }

              return new Date(globalMessage.node.hideAfter).getTime() > new Date().getTime();
            })
            .map(({ node: globalMessage }) => (
              <Message
                key={globalMessage.id}
                id={globalMessage.id}
                message={globalMessage.message}
                textColor={globalMessage.textColor}
                backgroundColor={globalMessage.backgroundColor}
              />
            ))}

        {messages}

        <SegmentBar
          path={path}
          onCookiesClick={(event) => {
            setCookieConsentOpen(true);

            setCookieConsentTrigger(event?.currentTarget as HTMLElement);
          }}
        />

        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <CookieConsent
              open={cookieConsentOpen}
              onClose={() => {
                if (!!Cookies.get('consentLevelCookie')) {
                  setCookieConsentOpen(false);

                  setTimeout(() => {
                    cookieConsentTrigger?.focus();

                    setCookieConsentTrigger(null);
                  });

                  return true;
                }

                return false;
              }}
              onAccept={(level: number) => {
                Cookies.set('consentLevelCookie', level.toString(), {
                  expires: 365,
                });
                Cookies.set('consentLevelCookieAt', Date.now().toString(), {
                  expires: 365,
                });

                setCookieConsentOpen(false);
              }}
              acceptedAt={Cookies.getJSON('consentLevelCookieAt')}
              defaultPane={!Cookies.get('consentLevelCookie') ? 'info' : 'settings'}
              defaultConsentLevel={
                !!Cookies.get('consentLevelCookie') ? Cookies.getJSON('consentLevelCookie') : 1
              }
            />
          </React.Suspense>
        )}

        <Navbar items={navItems(intl)} />

        <main>
          <Hero
            breadcrumbs={breadcrumbs}
            buttons={buttons}
            image={image}
            subTitle={subTitle}
            title={title}
          />

          {preMain}

          <div
            className={cc([
              'container',
              'mx-auto',
              'flex',
              'flex-col',
              'align-center',
              'justify-center',
              className,
            ])}
          >
            {children}
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
};

Layout.displayName = 'FcLayout';

export default Layout;
