import React from 'react';
import cc from 'classcat';
import { CSSTransition } from 'react-transition-group';
import { Link, useIntl } from 'gatsby-plugin-intl';
import useEventListener from '@use-it/event-listener';
import { MenuAlt4Icon, XIcon } from '@heroicons/react/outline';

// @ts-ignore
import logoDark from '../../assets/logos/qouldnt_dark.svg';
// @ts-ignore
import logoLight from '../../assets/logos/qouldnt_light.svg';

import { NavItem } from '../../data/nav-items';

export interface Props {
  items: NavItem[];
}

const Navbar: React.FC<Props> = (props) => {
  const { items } = props;

  const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false);

  const intl = useIntl();

  const mobileMenuButton = React.useRef<HTMLButtonElement>();

  const openMobileMenu = () => setMobileMenuOpen(true);
  const closeMobileMenu = () => setMobileMenuOpen(false);

  React.useEffect(() => {
    let resizeHandler = () => {};

    if (window) {
      const resizeHandler = () => {
        if (window.innerWidth >= 768) {
          closeMobileMenu();
        }
      };

      window.addEventListener('resize', resizeHandler, false);
    }

    return () => window && window.removeEventListener('resize', resizeHandler);
  }, [closeMobileMenu]);

  React.useEffect(() => {
    if (mobileMenuOpen) {
      mobileMenuButton?.current?.blur();
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [mobileMenuOpen]);

  const headerRef = React.useRef<HTMLDivElement>();
  const [headerScrolled, setHeaderScrolled] = React.useState<boolean>(false);

  useEventListener('scroll',() => {
    setHeaderScrolled(headerRef.current.offsetTop <= globalThis.scrollY);
  }, globalThis as typeof window);

  return (
    <header
      ref={headerRef}
      className={cc([
        'fc-navbar',
        'transition-shadow',
        'duration-100',
        'sticky',
        'top-0',
        'z-50',
        'bg-gray-100',
        'dark:bg-gray-900',
        'border-b-4',
        'border-accent',
        'select-none',
        'transition-colors',
        'duration-75',
        'ease-in-out',
        {
          'shadow-2xl': headerScrolled,
        },
      ])}
    >
      <div className="relative py-2 lg:py-4 px-4 sm:px-6 lg:px-8 max-w-screen-xl mx-auto">
        <nav className="relative flex items-center justify-between sm:h-10">
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link
                to="/"
                className={cc([
                  'py-2',
                  'px-4',
                  'rounded',
                  'inline-flex',
                  'focus-visible:outline-none',
                  'focus-visible:ring-2',
                  'ring-primary-active',
                  'motion-safe:transition',
                  'duration-150',
                  'ease-in-out',
                  'hover:bg-primary',
                  'hover:bg-opacity-10',
                  'focus-visible:bg-primary',
                  'focus-visible:bg-opacity-10',
                ])}
              >
                <img
                  alt="Qouldnt logo"
                  src={logoLight}
                  className="h-8 w-auto max-w-[100px] sm:h-10 hidden dark:inline-flex"
                  draggable={false}
                  width={100}
                  height={40}
                />
                <img
                  alt="Qouldnt logo"
                  src={logoDark}
                  className="h-8 w-auto max-w-[100px] sm:h-10 inline-flex dark:hidden"
                  draggable={false}
                  width={100}
                  height={40}
                />
              </Link>
              <div className="-mr-2 flex items-center md:hidden print:hidden">
                <button
                  ref={mobileMenuButton}
                  type="button"
                  className={cc([
                    'inline-flex',
                    'items-center',
                    'justify-center',
                    'p-2',
                    'w-10',
                    'h-10',
                    'rounded-md',
                    'text-gray-600',
                    'dark:text-gray-500',
                    'hover:bg-gray-300',
                    'focus-visible:bg-gray-300',
                    'dark:hover:bg-gray-800',
                    'dark:focus-visible:bg-gray-800',
                    'focus-visible:outline-none',
                    'focus-visible:ring-2',
                    'ring-primary-active',
                    'ring-offset-gray-100',
                    'dark:ring-offset-gray-900',
                    'ring-offset-4',
                    'motion-safe:transition',
                    'duration-150',
                    'ease-in-out',
                  ])}
                  onClick={openMobileMenu}
                  title={intl.formatMessage({
                    id: 'Open menu',
                    defaultMessage: 'Open menu',
                  })}
                >
                  <MenuAlt4Icon className='w-full h-full' />
                </button>
              </div>
            </div>
          </div>
          <div className="hidden md:block md:ml-10 -mr-4">
            {items.map((item) => (
              <Link
                key={item.to}
                to={item.to}
                className={cc([
                  'py-2',
                  'px-4',
                  'ml-6',
                  'first:ml-0',
                  'rounded',
                  'font-medium',
                  'focus:outline-none',
                  'motion-safe:transition',
                  'duration-150',
                  'ease-in-out',
                  'hover:bg-primary',
                  'hover:bg-opacity-10',
                  'focus-visible:bg-primary',
                  'focus-visible:bg-opacity-10',
                  'focus-visible:ring-2',
                  'ring-primary',
                  {
                    'text-gray-700': !item.customColor,
                    'hover:text-gray-900': !item.customColor,
                    'focus-visible:text-gray-900': !item.customColor,
                    'dark:text-gray-500': !item.customColor,
                    'dark:hover:text-gray-200': !item.customColor,
                    'dark:focus-visible:text-gray-100': !item.customColor,
                  },
                  item.customColor,
                  item.className,
                ])}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </nav>
      </div>

      <CSSTransition
        in={mobileMenuOpen}
        timeout={300}
        classNames={{
          enter: 'opacity-0',
          enterActive: 'motion-safe:transition-opacity duration-300 ease-out opacity-100',
          exitActive: 'motion-safe:transition-opacity duration-200 ease-in opacity-0',
        }}
        unmountOnExit={true}
      >
        <div className="fixed inset-0" onClick={closeMobileMenu}>
          <div
            className={cc([
              'absolute',
              'inset-0',
              'bg-gray-500',
              'dark:bg-gray-700',
              'opacity-75',
              'dark:opacity-90',
              'transition-colors',
              'duration-75',
              'ease-in-out',
            ])}
          />
        </div>
      </CSSTransition>

      <CSSTransition
        in={mobileMenuOpen}
        timeout={300}
        classNames={{
          enter: 'opacity-0 scale-95',
          enterActive: 'motion-safe:transition duration-150 ease-out opacity-100 scale-100',
          exitActive: 'motion-safe:transition duration-100 ease-in opacity-0 scale-95',
        }}
        unmountOnExit={true}
      >
        <div className="fixed top-0 inset-x-0 p-2 transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md">
            <div className="rounded-lg bg-white dark:bg-gray-800 shadow-xs overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div className="inline-flex">
                  <img
                    alt="Qouldnt logo"
                    src={logoLight}
                    className="h-8 w-auto hidden dark:inline-flex"
                    draggable={false}
                  />
                  <img
                    alt="Qouldnt logo"
                    src={logoDark}
                    className="h-8 w-auto inline-flex dark:hidden"
                    draggable={false}
                  />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className={cc([
                      'inline-flex',
                      'items-center',
                      'justify-center',
                      'p-2',
                      'w-10',
                      'h-10',
                      'rounded-md',
                      'text-gray-600',
                      'dark:text-gray-500',
                      'hover:bg-gray-300',
                      'focus-visible:bg-gray-300',
                      'dark:hover:bg-gray-700',
                      'dark:focus-visible:bg-gray-700',
                      'focus-visible:outline-none',
                      'focus-visible:ring-2',
                      'ring-primary-active',
                      'ring-offset-gray-100',
                      'dark:ring-offset-gray-800',
                      'ring-offset-4',
                      'motion-safe:transition',
                      'duration-150',
                      'ease-in-out',
                    ])}
                    onClick={closeMobileMenu}
                    title={intl.formatMessage({
                      id: 'Close menu',
                      defaultMessage: 'Close menu',
                    })}
                  >
                    <XIcon className='w-full h-full' />
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3" onClick={closeMobileMenu}>
                {items.map((item) => (
                  <Link
                    key={item.to}
                    to={item.to}
                    className={cc([
                      'block',
                      'px-3',
                      'py-2',
                      'text-base',
                      'font-medium',
                      'focus:outline-none',
                      'border-b-4',
                      'border-transparent',
                      'hover:border-primary',
                      'focus-visible:border-primary',
                      'motion-safe:transition',
                      'duration-150',
                      'ease-in-out',
                      {
                        'text-gray-700': !item.customColor,
                        'hover:text-gray-900': !item.customColor,
                        'focus-visible:text-gray-900': !item.customColor,
                        'dark:text-gray-500': !item.customColor,
                        'dark:hover:text-gray-200': !item.customColor,
                        'dark:focus-visible:text-gray-100': !item.customColor,
                      },
                      item.customColor,
                      item.className,
                    ])}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </header>
  );
};

Navbar.displayName = 'FcNavbar';

export default Navbar;
