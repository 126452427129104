import React from 'react';
import cc from 'classcat';
import { FormattedMessage } from 'gatsby-plugin-intl';

export interface Props {
  entity: string;
  startYear?: string;
  className?: string;
  [key: string]: any;
}

const Copyright: React.FC<Props> = (props) => {
  const { entity, className, startYear, ...restProps } = props;

  const currentYear = new Date().getFullYear().toString();

  return (
    <div {...restProps} className={cc(['fc-copyright', className])}>
      <FormattedMessage
        id="Copyright © {startYear} - {currentYear} {entity}. All rights reserved."
        values={{
          currentYear,
          entity,
          startYear,
        }}
      />
    </div>
  );
};

Copyright.displayName = 'FcCopyright';

Copyright.defaultProps = {
  startYear: '2020',
};

export default Copyright;
