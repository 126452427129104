import React from 'react';
import cc from 'classcat';
import { useIntl } from 'gatsby-plugin-intl';
import Icon from '../icon';

export interface Props {
  children: React.ReactNode
  href: string
  styling?: boolean
  title?: string
}

const ExternalLink: React.FC<Props> = (props) => {
  const {
    children,
    href,
    styling = true,
    title,
  } = props;

  const intl = useIntl();

  const titleSuffix = intl.formatMessage({
    id: 'External link to: {host}',
    defaultMessage: 'External link to: {host}',
  }, {
    host: href
      .replace('http://','')
      .replace('https://','')
      .replace('ftp://','')
      .replace('ftps://','')
      .split(/[/?#]/)[0],
  });

  return (
    <a
      rel="noopener noreferrer"
      href={href}
      className={cc([
        'fc-external-link',
        'hover:text-blue-900',
        'dark:hover:text-blue-200',
        'motion-safe:transition-colors',
        'duration-150',
        {
          'text-blue-700': styling,
          'dark:text-blue-400': styling,
        },
      ])}
      target="_blank"
      title={title ? `${title} | ${titleSuffix}` : titleSuffix}
    >
      <span className="underline">{children}</span>{' '}
      <Icon name="ExternalLink" className="h-4 inline-block" />
    </a>
  );
};

ExternalLink.displayName = 'FcExternalLink';

ExternalLink.defaultProps = {
  styling: true,
};

export default ExternalLink;

