import React from 'react';
import cc from 'classcat';
import { INLINES } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

import ExternalLink from '../external-link';

interface Props {
  id: string;
  backgroundColor: string;
  message: RenderRichTextData<any>;
  textColor: string;
}

const documentToReactComponentsOptions: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <ExternalLink href={node.data.uri} styling={false}>
        {children}
      </ExternalLink>
    ),
  },
};

const Message: React.FC<Props> = (props) => {
  const { backgroundColor, message, textColor } = props;

  return (
    <div
      className={cc([
        'fc-layout-message',
        'p-4',
        'text-center',
        'font-display',
        'text-xl',
        `text-${textColor}`,
        `bg-${backgroundColor}`,
      ])}
    >
      {renderRichText(message, documentToReactComponentsOptions)}
    </div>
  );
};

Message.displayName = 'FcLayoutMessage';

export default Message;
