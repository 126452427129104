import React from 'react';

export type IconName =
  | 'AcademicCap'
  | 'Adjustments'
  | 'Annotation'
  | 'Archive'
  | 'ArrowCircleDown'
  | 'ArrowCircleLeft'
  | 'ArrowCircleRight'
  | 'ArrowCircleUp'
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowNarrowDown'
  | 'ArrowNarrowLeft'
  | 'ArrowNarrowRight'
  | 'ArrowNarrowUp'
  | 'ArrowRight'
  | 'ArrowSmDown'
  | 'ArrowSmLeft'
  | 'ArrowSmRight'
  | 'ArrowSmUp'
  | 'ArrowUp'
  | 'ArrowsExpand'
  | 'AtSymbol'
  | 'Backspace'
  | 'BadgeCheck'
  | 'Ban'
  | 'Beaker'
  | 'Bell'
  | 'BookOpen'
  | 'BookmarkAlt'
  | 'Bookmark'
  | 'Briefcase'
  | 'Cake'
  | 'Calculator'
  | 'Calendar'
  | 'Camera'
  | 'Cash'
  | 'ChartBar'
  | 'ChartPie'
  | 'ChartSquareBar'
  | 'ChatAlt2'
  | 'ChatAlt'
  | 'Chat'
  | 'CheckCircle'
  | 'Check'
  | 'ChevronDoubleDown'
  | 'ChevronDoubleLeft'
  | 'ChevronDoubleRight'
  | 'ChevronDoubleUp'
  | 'ChevronDown'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'ChevronUp'
  | 'Chip'
  | 'ClipboardCheck'
  | 'ClipboardCopy'
  | 'Clipboard'
  | 'ClipboardList'
  | 'Clock'
  | 'CloudDownload'
  | 'Cloud'
  | 'CloudUpload'
  | 'Code'
  | 'Cog'
  | 'Collection'
  | 'ColorSwatch'
  | 'CreditCard'
  | 'Cube'
  | 'CubeTransparent'
  | 'CurrencyBangladeshi'
  | 'CurrencyDollar'
  | 'CurrencyEuro'
  | 'CurrencyPound'
  | 'CurrencyRupee'
  | 'CurrencyYen'
  | 'CursorClick'
  | 'Database'
  | 'DesktopComputer'
  | 'DeviceMobile'
  | 'DeviceTablet'
  | 'DocumentAdd'
  | 'DocumentDownload'
  | 'DocumentDuplicate'
  | 'Document'
  | 'DocumentRemove'
  | 'DocumentReport'
  | 'DocumentSearch'
  | 'DocumentText'
  | 'DotsCircleHorizontal'
  | 'DotsHorizontal'
  | 'DotsVertical'
  | 'Download'
  | 'Duplicate'
  | 'EmojiHappy'
  | 'EmojiSad'
  | 'ExclamationCircle'
  | 'Exclamation'
  | 'ExternalLink'
  | 'Eye'
  | 'EyeOff'
  | 'FastForward'
  | 'Film'
  | 'Filter'
  | 'FingerPrint'
  | 'Fire'
  | 'Flag'
  | 'FolderAdd'
  | 'FolderDownload'
  | 'Folder'
  | 'FolderOpen'
  | 'FolderRemove'
  | 'Gift'
  | 'GlobeAlt'
  | 'Globe'
  | 'Hand'
  | 'Hashtag'
  | 'Heart'
  | 'Home'
  | 'Identification'
  | 'Inbox'
  | 'InboxIn'
  | 'InformationCircle'
  | 'Key'
  | 'Library'
  | 'LightBulb'
  | 'LightningBolt'
  | 'Link'
  | 'LocationMarker'
  | 'LockClosed'
  | 'LockOpen'
  | 'Login'
  | 'Logout'
  | 'Mail'
  | 'MailOpen'
  | 'Map'
  | 'MenuAlt1'
  | 'MenuAlt2'
  | 'MenuAlt3'
  | 'MenuAlt4'
  | 'Menu'
  | 'Microphone'
  | 'MinusCircle'
  | 'Minus'
  | 'MinusSm'
  | 'Moon'
  | 'MusicNote'
  | 'Newspaper'
  | 'OfficeBuilding'
  | 'PaperAirplane'
  | 'PaperClip'
  | 'Pause'
  | 'PencilAlt'
  | 'Pencil'
  | 'Phone'
  | 'PhoneIncoming'
  | 'PhoneMissedCall'
  | 'PhoneOutgoing'
  | 'Photograph'
  | 'Play'
  | 'PlusCircle'
  | 'Plus'
  | 'PlusSm'
  | 'PresentationChartBar'
  | 'PresentationChartLine'
  | 'Printer'
  | 'Puzzle'
  | 'Qrcode'
  | 'QuestionMarkCircle'
  | 'ReceiptRefund'
  | 'ReceiptTax'
  | 'Refresh'
  | 'Reply'
  | 'Rewind'
  | 'Rss'
  | 'SaveAs'
  | 'Save'
  | 'Scale'
  | 'Scissors'
  | 'SearchCircle'
  | 'Search'
  | 'Selector'
  | 'Server'
  | 'Share'
  | 'ShieldCheck'
  | 'ShieldExclamation'
  | 'ShoppingBag'
  | 'ShoppingCart'
  | 'SortAscending'
  | 'SortDescending'
  | 'Sparkles'
  | 'Speakerphone'
  | 'Star'
  | 'StatusOffline'
  | 'StatusOnline'
  | 'Stop'
  | 'Sun'
  | 'Support'
  | 'SwitchHorizontal'
  | 'SwitchVertical'
  | 'Table'
  | 'Tag'
  | 'Template'
  | 'Terminal'
  | 'ThumbDown'
  | 'ThumbUp'
  | 'Ticket'
  | 'Translate'
  | 'Trash'
  | 'TrendingDown'
  | 'TrendingUp'
  | 'Truck'
  | 'Upload'
  | 'UserAdd'
  | 'UserCircle'
  | 'UserGroup'
  | 'User'
  | 'UserRemove'
  | 'Users'
  | 'Variable'
  | 'VideoCamera'
  | 'ViewBoards'
  | 'ViewGridAdd'
  | 'ViewGrid'
  | 'ViewList'
  | 'VolumeOff'
  | 'VolumeUp'
  | 'Wifi'
  | 'XCircle'
  | 'X'
  | 'ZoomIn'
  | 'ZoomOut';

export interface Props extends React.ComponentProps<'svg'> {
  name: IconName;
  solid?: boolean;
}

const Icon: React.FC<Props> = (props) => {
  const { name, solid, ...restProps } = props;

  const isSSR = typeof window === 'undefined';

  if (isSSR) {
    return null;
  }

  const style = solid ? 'solid' : 'outline';
  const IconComponent = React.lazy(() => import(`@heroicons/react/${style}/${name}Icon`));

  return (
    <React.Suspense fallback={null}>
      <IconComponent {...restProps} />
    </React.Suspense>
  );
};

Icon.displayName = 'FcIcon';

export default Icon;
