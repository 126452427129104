import React from 'react';
import cc from 'classcat';

export interface Props {
  breadcrumbs?: React.ReactNode;
  buttons?: React.ReactNode;
  image?: React.ReactNode;
  subTitle?: React.ReactNode;
  title?: React.ReactNode;
}

const Hero: React.FC<Props> = (props) => {
  const { breadcrumbs, buttons, image, subTitle, title } = props;

  const hasImage = !!image;

  return (
    <div className={cc(['fc-hero', 'relative', 'overflow-hidden'])}>
      <div className="max-w-screen-xl mx-auto flex flex-col justify-start items-start">
        <div
          className={cc([
            'relative',
            'z-10',
            'px-2',
            'rounded',
            'w-full',
            {
              'pb-8': !hasImage,
              'my-16': hasImage,
              // 'bg-gray-100': hasImage,
              // 'dark:bg-gray-900': hasImage,
              // 'bg-opacity-80': hasImage,
              // 'dark:bg-opacity-80': hasImage,
              // 'lg:max-w-2xl': hasImage,
              // 'transition-colors': hasImage,
              // 'duration-200': hasImage,
              // 'ease-in-out': hasImage,
            },
          ])}
        >
          {
            breadcrumbs &&
            <div
              className={cc([
                'mx-auto',
                'md:mx-0',
                'p-3',
                // 'pb-3',
                'sm:px-6',
                'lg:px-8',
                'relative',
                'z-10',
                'rounded',
                // 'w-auto',
                'inline-block',
                {
                  'mb-8': hasImage,
                  'bg-gray-100': hasImage,
                  'dark:bg-gray-900': hasImage,
                  'bg-opacity-80': hasImage,
                  'dark:bg-opacity-80': hasImage,
                  'lg:max-w-2xl': hasImage,
                  'transition-colors': hasImage,
                  'duration-200': hasImage,
                  'ease-in-out': hasImage,
                },
              ])}
            >
              {breadcrumbs}
            </div>
          }

          <div
            className={cc([
              'mx-auto',
              'md:mx-0',
              'px-4',
              'py-8',
              'sm:px-6',
              'lg:px-8',
              'relative',
              'z-10',
              'rounded',
              // 'lg:w-full',
              {
                // 'my-16': hasImage,
                'bg-gray-100': hasImage,
                'dark:bg-gray-900': hasImage,
                'bg-opacity-80': hasImage,
                'dark:bg-opacity-80': hasImage,
                'lg:max-w-xl': hasImage,
                'transition-colors': hasImage,
                'duration-200': hasImage,
                'ease-in-out': hasImage,
              },
            ])}
          >
            <div
              className={cc([
                'text-center',
                {
                  'mt-12': !hasImage,
                  'lg:text-left': hasImage,
                },
              ])}
            >
              <h1
                className={cc([
                  'font-display',
                  'tracking-tight',
                  'leading-tight',
                  'font-extrabold',
                  'sm:leading-none',
                  'text-4xl',
                  'sm:text-6xl',
                  'md:text-8xl',
                ])}
              >
                {title}
              </h1>
              <div
                className={cc([
                  'mt-3',
                  'text-lg',
                  'text-gray-700',
                  'dark:text-gray-300',
                  'sm:mt-5',
                  'sm:text-lg',
                  'sm:mx-auto',
                  'md:mt-5',
                  'md:text-2xl',
                  'lg:mx-0',
                  'transition-colors',
                  'duration-75',
                  'ease-in-out',
                ])}
              >
                {subTitle}
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                {buttons}
              </div>
            </div>
          </div>
        </div>
      </div>
      {hasImage && <div className="lg:block lg:absolute lg:inset-0">{image}</div>}
    </div>
  );
};

Hero.displayName = 'FcHero';

export default Hero;
