const commaAnd = (items: any[], and: string) => {
  const itemsCopy = [...items].filter(item => item);
  const lastItem = itemsCopy.pop();

  if (itemsCopy.length <= 0) {
    return `${lastItem}`;
  }

  return `${itemsCopy.join(', ')} ${and} ${lastItem}`;
};

export default commaAnd;
