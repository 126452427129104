import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';

import Copyright from '../copyright';
import commaAnd from '../../utils/comma-and';

// @ts-ignore
import logoDark from '../../assets/logos/qouldntab_dark.svg';
// @ts-ignore
import logoLight from '../../assets/logos/qouldntab_light.svg';

const Footer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      companyInfo {
        entity {
          legalName
          vatNumber
        }
        brands
      }
    }
  `);

  const intl = useIntl();

  return (
    <footer className="fc-footer mt-16 pb-4 text-lg">
      <div className="mx-auto px-8 py-16 text-center">
        <div className="mb-4 print:hidden">
          <img
            alt="Qouldnt AB logo"
            src={logoDark}
            className="w-[120px] mx-auto py-2 dark:hidden"
            draggable={false}
            width={120}
            height={35}
          />
          <img
            alt="Qouldnt AB logo"
            src={logoLight}
            className="w-[120px] mx-auto py-2 hidden dark:block"
            draggable={false}
            width={120}
            height={35}
          />
        </div>

        <Copyright className="center" entity={data.companyInfo.entity.legalName} />

        <p className="mt-1 text-gray-800 dark:text-gray-400">
          <FormattedMessage
            id="{brands} are trademarks of {entity}."
            values={{
              brands: commaAnd(
                data.companyInfo.brands,
                intl.formatMessage({ id: 'and', defaultMessage: 'and' })
              ),
              entity: data.companyInfo.entity.legalName,
            }}
            defaultMessage="{brands} are trademarks of {entity}."
          />
        </p>
        <p className="mt-2 text-gray-700 dark:text-gray-500">
          {data.companyInfo.entity.legalName}, {data.companyInfo.entity.vatNumber}
        </p>
      </div>

      <div className="w-full md:hidden mb-8">
        <div className="container flex justify-center mx-auto text-xs align-middle leading-8">
          <div className="flex mr-8">
            <Link
              to="/legal/privacy"
              className="text-lg ml-8 font-bold text-gray-600 dark:text-gray-500 hover:text-gray-900 dark:hover:text-white cursor-pointer transition duration-150"
            >
              <FormattedMessage id="Privacy" defaultMessage="Privacy" />
            </Link>
            <Link
              to="/legal/terms"
              className="text-lg ml-8 font-bold text-gray-600 dark:text-gray-500 hover:text-gray-900 dark:hover:text-white cursor-pointer transition duration-150"
            >
              <FormattedMessage id="Terms" defaultMessage="Terms" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.displayName = 'FcFooter';

export default Footer;
